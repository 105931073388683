import React, { useEffect, useState } from 'react'
import DayRoutine from './DayRoutine'

function WeekRoutine(props) {
    const [weekdays, setWeekdays] = useState()
    useEffect(()=>{
        const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
    setWeekdays(days);
    },[])
  return (
    <div className='weekroutine'>
      {weekdays && weekdays.map((day)=>{
        return(
            <DayRoutine studentClass={"CST_2A"} day={day}></DayRoutine>
        )
      })}
    </div>
  )
}

export default WeekRoutine
