import React, { useEffect, useState } from 'react'
import PeriodCard from './PeriodCard';

function DayRoutine(props) {
  const [day, setDay] = useState()
  const [routine, setRoutine] = useState()

  useEffect(()=>{
    const studentClass = props.studentClass;
    // const studentClass = "CST_2A";
    // const day = "monday";
    const day = props.day;
    console.log(process.env.REACT_APP_API_URL+`${studentClass}/${day}`)

    fetch(process.env.REACT_APP_API_URL+`${studentClass}/${day}`, {method: 'GET'}).then(result=>result.json()).then(res=>{
      console.log(res.data)
      setRoutine(res.data.routine);
      setDay(res.data.day)
    })

  },[])

  return (
    <>
    {routine && <div className='dayroutine'>
      <p className="dayroutine__day">{day}</p>
    {routine.map(period=><PeriodCard periodDetails={period}></PeriodCard>)}
    </div>}
    </>
  )
}

export default DayRoutine
