import React, { useEffect, useState } from 'react'

function PeriodCard({periodDetails}) {
    const [period, setPeriod] = useState()

    useEffect(()=>{
        setPeriod(periodDetails);
    },[])

  return (
    <div className='periodcard'>
      {periodDetails.subject && <h2 className='periodcard__subject'>{periodDetails.subject}</h2>}

      {periodDetails.faculty && <p className='periodcard__text periodcard__faculty'>{periodDetails.faculty}</p>}

      {periodDetails.room && <p className='periodcard__text periodcard__room'>{periodDetails.room}</p>}

      {periodDetails.start && <p className='periodcard__text periodcard__time'>{periodDetails.start+"-"+periodDetails.end}</p>}
    </div>
  )
}

export default PeriodCard
