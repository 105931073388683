import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import './components/css/main.css';
import DayRoutine from './components/DayRoutine';
import NextRoutine from './components/NextRoutine';
import WeekRoutine from './components/WeekRoutine';

function App() {

  const [weekdays, setWeekdays] = useState()

  useEffect(()=>{
    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
    setWeekdays(days);

    const currentDate = new Date();
    const currentDay = days[currentDate.getDay()];
    const currentTime = (currentDate.getHours()*60) + currentDate.getMinutes();
    console.log(currentTime)
  },[])
  return (
    <>
    <BrowserRouter>
    <header className="header">Routine</header>
    <div className="btn-group">
      <NavLink to={"/weekly"}><button className="btn btn--primary">Weekly</button></NavLink>
      <NavLink to={"/daily"}><button className="btn btn--primary">Daily</button></NavLink>
      <NavLink to={"/next"}><button className="btn btn--primary">Next</button></NavLink>
    </div>

    <Routes>
      <Route path='/weekly' element={<WeekRoutine days={weekdays}></WeekRoutine>}></Route>
      <Route path='/daily' element={<DayRoutine studentClass={"CST_2A"} day={"monday"}></DayRoutine>}></Route>
      <Route path='/next' element={<NextRoutine></NextRoutine>}></Route>
    </Routes>

    </BrowserRouter>
    </>
  );
}

export default App;
